.Details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-top: 50px;
}

.DetailTable{
    margin: auto;
    margin-top: 50px;
}

.Map{
    margin-top: 50px;
}

.PurpleCircle{
    display: flex;
    width: 44px;
    height: 44px;
    background-color: #794396;
    border-radius: 50%;
    margin: auto;
    margin-top: 5px;
}

.GrayCircle{
    display: flex;
    width: 44px;
    height: 44px;
    background-color: #D4D3D3;
    border-radius: 50%;
    margin: auto;
}

.VerticalLine{
    position: fixed;
    border-left: 2px dotted #1D2222;
    height: 300px;
    right: 56.9%;
    z-index: 1;
    transform: translate(-50%, -50%);
}