.SearchButton{
    background-color: #794396;
    border-color: #794396;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    width: 344px;
    height: 36px;
    margin-top: 20px;
}

.InputSection{
    width: 344px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
}