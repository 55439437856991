.LogoBar{
    display: flex;
    align-items: center;
    margin: auto;
    width: auto;
    height: 100px;
    background-color: #191919;
    padding: 3.5px 28px 3.5px 28px;
}

.LogoImage{
    margin: auto;
}